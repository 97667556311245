import { Subscriber } from 'rxjs'
import axios from 'axios'

export default class Put extends Subscriber {
  constructor(observer, url, params, argHeaders) {
    super(observer)
    this.completed = false
    const instance = axios.create({
      headers: Object.assign(argHeaders, {
        'Access-Control-Allow-Origin': '*',
      }),
    })
    instance
      .put(url, params)
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error?.response?.data ?? error.message)
      })
  }
}
