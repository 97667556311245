import GET from './get'
import POST from './post'
import PUT from './put'
import DELETE from './delete'

export default {
  GET,
  POST,
  PUT,
  DELETE,
}
